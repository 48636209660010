import $ from 'jquery';
import UserToken,{checkUserSession} from '../function_lib/userToken';
class listData {
    constructor(objName, testing) {
        this.refClass = this;
        this.objName = objName
        this.testing = testing;
        this.purchaseItem = { tt: [] };

        this.editIndex=-1;
        this.canEdit=true;
        this.canDelete=true;
        //alert(objName);

        this.formFormuals=[];

        this.clickObject = document.getElementById(objName);
        this.mainMember = this.clickObject.getAttribute("clmname");
        this.cldMember = this.clickObject.getAttribute("cldname");
        this.resMember = this.clickObject.getAttribute("resname");
        this.cldHidMember = this.clickObject.getAttribute("cldhidname");
        this.resValue = this.clickObject.getAttribute("resValue");
        this.piMembers = document.getElementsByClassName(this.cldMember);
        this.piHidMembers = document.getElementsByClassName(this.cldHidMember);
        this.clickObject.removeEventListener('click',(e) => this.setEvents(e));
        this.clickObject.addEventListener('click', (e) => this.setEvents(e));
        //define callbacks
        
        this.preSetInvoiceData="";
        this.preEventSetData="";
        this.editLoadDocumentTb="";
        this.editLoadDocPopup="";
        this.editCompleteCBack=""
        this.readOnly="";

      
    }

    clearFieldsviaClass() {
        for (var i = 0; i < this.piMembers.length; i++) {
            this.piMembers[i].value = "";
        }
        //document.getElementById("sel_auto_item_name").value="";
        if (typeof this.readOnly === "function") {
            this.readOnly(false);
        }

    }

    setFormFormula(clsObject){
        //alert("i m in formual");
        for(let i=0;i<this.formFormuals.length;i++){
            let calFields=this.formFormuals[i]["cal_fields"].split(',');
            let calc_sign=this.formFormuals[i]["calc_sign"];
            let outPutFiled=this.formFormuals[i]["output"];

            let calcOut=0;
            let calcMultiply=1;
            let tmpAllFields=[];
            for(let k=0;k<calFields.length;k++){
                //alert(this.isFloat(calFields[k]));
                    if(this.isFloat(calFields[k])===false){
                        let tmpOut=document.getElementById(calFields[k]).value;

                        if(this.isFloat(tmpOut)){
                            tmpAllFields.push(tmpOut);
                        }else{
                            tmpAllFields.push("0");
                        }
                    }else{
                        //alert(calFields[k]);
                        tmpAllFields.push(calFields[k]);
                    }
                    
            }
            if(tmpAllFields.length===calFields.length || 0===0){

                for(let t=0;t<tmpAllFields.length;t++){
                    if(calc_sign==="+"){
                        calcOut+=parseFloat(tmpAllFields[t]);
                        document.getElementById(outPutFiled).value=calcOut;
                    }
                    if(calc_sign==="-"){
                        if(calcOut===0){
                            calcOut=parseFloat(tmpAllFields[t]);
                        }else{
                            calcOut-=parseFloat(tmpAllFields[t]);
                        }
                        
                        document.getElementById(outPutFiled).value=calcOut;
                    }
                    if(calc_sign==="*"){
                        calcMultiply*=parseFloat(tmpAllFields[t]);
                        document.getElementById(outPutFiled).value=calcMultiply;
                    }

                }
            }
            

        }
    }

    setAutoCalculationChange(clsObject,event,calcType="rate"){
        if(calcType==="rate"){
            let meControl=event.target.getAttribute("id");
            let relateControl=event.target.getAttribute("relate_control");
            let outputControl=event.target.getAttribute("output_to");
            //alert(meControl+","+relateControl+","+outputControl);
            if(typeof relateControl!=="undefined" && typeof relateControl!==false){
                if(typeof outputControl!=="undefined" && typeof outputControl!==false){
                    //alert("i m in condition on change");
                    let objMe=document.getElementById(meControl);
                    let objRelate=document.getElementById(relateControl);
                    let objOutput=document.getElementById(outputControl);
                    
                    let checkObject=false;
                    if(objMe && objRelate && objOutput){
                        let meValue=0;
                        let relateValue=0;
                        let outputValue=0;
                        if(clsObject.isFloat(objMe.value)){
                            meValue=objMe.value;
                        }
                        if(clsObject.isFloat(objRelate.value)){
                            relateValue=objRelate.value;
                        }
                        //alert(meValue+","+relateValue);
                        
                        if(meValue!==0 && relateValue!==0){
                            objOutput.value=meValue*relateValue;
                        }

                    }


                }
            }
        }else if(calcType==="discount_per"){
            let meControl=event.target.getAttribute("id");
            let relateControl=event.target.getAttribute("relate_control");
            let outputControl=event.target.getAttribute("output_to");
            if(typeof relateControl!=="undefined" && typeof relateControl!==false){
                if(typeof outputControl!=="undefined" && typeof outputControl!==false){
                    let objMe=document.getElementById(meControl);
                    let objRelate=document.getElementById(relateControl);
                    let objOutput=document.getElementById(outputControl);
                    
                    let checkObject=false;
                    if(objMe && objRelate && objOutput){
                        let meValue=0;
                        let relateValue=0;
                        let outputValue=0;
                        if(objMe.value.trim()!==""){
                            if(clsObject.isFloat(objMe.value)){
                                meValue=objMe.value;
                            }
                        }
                        
                        if(clsObject.isFloat(objRelate.value)){
                            relateValue=objRelate.value;
                        }
                        //alert(meValue+","+relateValue);
                        
                        if(relateValue!==0){
                            objOutput.value=(relateValue-(relateValue*meValue/100)).toFixed(2);
                        }

                    }
                }
            }
        }else if(calcType==="sum" || calcType==="sub"){
            let meControl=event.target.getAttribute("id");
            let relateControl=event.target.getAttribute("relate_control");
            let outputControl=event.target.getAttribute("output_to");
            if(typeof relateControl!=="undefined" && typeof relateControl!==false){
                if(typeof outputControl!=="undefined" && typeof outputControl!==false){
                    let objMe=document.getElementById(meControl);
                    let objRelate=document.getElementById(relateControl);
                    let objOutput=document.getElementById(outputControl);
                    
                    let checkObject=false;
                    if(objMe && objRelate && objOutput){
                        let meValue=0;
                        let relateValue=0;
                        let outputValue=0;
                        if(objMe.value.trim()!==""){
                            if(clsObject.isFloat(objMe.value)){
                                meValue=parseFloat(objMe.value);
                            }
                        }
                        
                        if(clsObject.isFloat(objRelate.value)){
                            relateValue=parseFloat(objRelate.value);
                        }
                        //alert(meValue+","+relateValue);
                        if(calcType==="sum"){
                            objOutput.value=relateValue+meValue;
                        }else if(calcType==="sub"){
                            if(relateValue>=meValue){
                                objOutput.value=relateValue-meValue;
                            }else{
                                objOutput.value=meValue-relateValue;
                            }
                            
                        }
                        
                        if(relateValue!==0){
                            let meValue=0;
                        }

                    }
                }
            }
        }else if(calcType==="meter_to_ton"){
            let meControl=event.target.getAttribute("id");
            let outputControl=event.target.getAttribute("output_to");
            if(typeof outputControl!=="undefined" && typeof outputControl!==false)
            {
                let objMe=document.getElementById(meControl);
                let objOutput=document.getElementById(outputControl);
                if(objMe && objOutput){
                    let meValue=0;
                    let outputValue=0;
                    if(objMe.value.trim()!==""){
                        if(clsObject.isFloat(objMe.value)){
                            meValue=parseFloat(objMe.value);
                            objOutput.value=meValue/1000;
                        }
                    }

                }

            }
        }
        this.setFormFormula(clsObject);
    }

    setOnChangeEvent(type="invoice",clsObject){
       
        if(type==="fee_breakdown"){

        
            $(".cd_input_change").change(function (event) {
                //alert(event.target);
                clsObject.setAutoCalculationChange(clsObject,event,"rate");
                
            });
            $(".cd_input_change").keyup(function (event) {
                //alert(event.target);
                clsObject.setAutoCalculationChange(clsObject,event,"rate");
                
            });


            $(".cd_input_change_perc").change(function (event) {
                clsObject.setAutoCalculationChange(clsObject,event,"discount_per");
            });
            $(".cd_input_change_sum").keyup(function(event){
                clsObject.setAutoCalculationChange(clsObject,event,"sum");
            });

            $(".cd_input_change_sub").keyup(function(event){
                clsObject.setAutoCalculationChange(clsObject,event,"sub");
            });
            $(".cd_input_change_sub").change(function(event){
                clsObject.setAutoCalculationChange(clsObject,event,"sub");
            });

            $(".cd_input_change_meter_ton").keyup(function(event){
                clsObject.setAutoCalculationChange(clsObject,event,"meter_to_ton");
            })
        }
    }
    isFloat(n) {
        let checkNum = false;
        if (Number(n)) {
            checkNum = true;
            //alert("number=="+n);
        } else if (Number(n) === n && n % 1 !== 0) {
            //alert("float=="+n);
            checkNum = true;
        } else {
            checkNum = false;
        }
    
        return checkNum;
    }
    setEvents(ee) {
        checkUserSession(false);
        //alert("i m in add event");

        if(ee.target.hasAttribute("validateStock")){
            //alert(ee.target.getAttribute("validateStock"))
            let strStockRelObjs=ee.target.getAttribute("validateStock");
            if(strStockRelObjs.trim()!==""){
                let arrStockRelObjs=strStockRelObjs.split(',');
                if(arrStockRelObjs.length>1){
                    let outQuantity=parseFloat(document.getElementById(arrStockRelObjs[0]).value);
                    let currentStock=parseFloat(document.getElementById(arrStockRelObjs[1]).value);
                    if((currentStock-outQuantity)<0){
                        alert("Stock Exceed the litmit, kindly check ..");
                        return;
                    }
                }
            }

        }

        if(this.editIndex===-1){
            this.setDataNew(this.piMembers, this.piHidMembers);
        }else{
            this.editDataNew(this.piMembers, this.piHidMembers)
        }
        
        /*
         * switch current method to bind Event
        for (let de = 0; de < this.purchaseItem.tt.length; de++) {
            document.getElementById(this.objName + "_de_" + (de + 1)).addEventListener("click", (e) => this.refDel(e, (de)), false);
        }
        End of Bind Event
        */
        //alert(this.objName + "_de_" + this.purchaseItem.tt.length);
        
        /*
        var displayList = this.setData(this.piMembers, this.piHidMembers);
        if (displayList == "0") {
            return;
        }
        
        var resPI = "";
       
        var resPI = "<tr id=\""+this.objName + this.purchaseItem.tt.length + "\">" + displayList + "</tr>";
        $("#" + this.resValue).val(JSON.stringify(this.purchaseItem));
        $("#" + this.resMember).append(resPI);
        document.getElementById(this.objName + "_de_" + this.purchaseItem.tt.length).addEventListener("click", (e) => this.refDel(e, (this.purchaseItem.tt.length - 1)), false);
        */
        
    }

    clearInsertedData() {
        $("#" + this.resValue).val("");
        $("#" + this.resMember).html("");
        this.purchaseItem = { tt: [] };
    }

     

    getValue() {
        return this.purchaseItem.tt;
    }
    refEdit(ee,index){
        if (typeof this.editLoadDocPopup === "function") {
            this.editLoadDocPopup(ee,index);
          }
    }
    refDel(ee, index) {
        //alert("i am in index " + index);
        if (window.confirm("Do you want to delete existing item !") === true) {
            this.purchaseItem.tt.splice(index, 1);
            $("#" + this.resValue).val(JSON.stringify(this.purchaseItem));
            let rmIndex = parseInt(index) + 1;
            document.getElementById(this.objName + rmIndex).remove();
            //alert("Deleted Successfully");

            if (typeof this.preEventSetData === "function") {
                if ($("#fctmpt").val() != "") {
                    this.preEventSetData($("#fctmpt").val());
                }
            }
            this.setExistingData();
            this.calcData();
        }
    }

    calcData() {
        let pAmount = 0;
        for (let p = 0; p < this.purchaseItem.tt.length; p++) {
            pAmount += parseFloat(this.purchaseItem.tt[p].amount);
           
        }
        if (this.purchaseItem.tt.length > 0) {
            if (typeof this.purchaseItem.tt[0].amount !== "undefined") {
                //alert(pAmount);
                if (typeof this.preSetInvoiceData === "function") {
                this.preSetInvoiceData(pAmount);
                }
            }
        }
        
    }

    setExistingData() {
        let exRespi = "";
        this.displayList = "";
        $("#" + this.resValue).val("");
        $("#" + this.resMember).html("");
        //alert("i am in set existing 1122");
        for (let i = 0; i < this.purchaseItem.tt.length; i++) {
            this.displayList = "";
            for (var k = 0; k < this.piMembers.length; k++) {
                let clsEdit = "";
                let memName = this.piMembers[k].id;
                if (this.piMembers[k].hasAttribute("rfnode")) {
                    memName = this.piMembers[k].getAttribute("rfnode");
                }
                if (memName === "quantity" || memName === "rate" ||
                    memName === "discount_per" || memName==="gov_department") {
                    clsEdit = "editable"
                }
                //alert(memName + "  " + this.purchaseItem.tt[i][memName]);
                let displayValue = "";
                if (typeof this.purchaseItem.tt[i][memName] !== "undefined" && this.purchaseItem.tt[i][memName]!=="undefined") {
                    displayValue = this.purchaseItem.tt[i][memName];
                }
                this.displayList = this.displayList + "<td e_key=\"" + memName + "\" e_id=\"" + i + "\"  class=\"" + clsEdit + "\">" + displayValue + "</td>";
            }
            let strEditDelete="";
            if(this.canEdit===true){
               strEditDelete= "<a id=\"" + this.objName + "_edit_" + (i+1) + "\" ref_index=\"" + i + "\" href=\"javascript:void(0)\"><i class=\"fa fa-pencil\" aria-hidden=\"true\"></i></a>";
            }
            if(this.canDelete===true){
                strEditDelete+="<a style=\"float:right\" id=\"" + this.objName + "_de_" + (i+1) + "\" ref_index=\"" + i + "\" href=\"javascript:void(0)\"><i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></a>";
            }
            this.displayList += "<td>"+
            strEditDelete+
            "</td>";
            exRespi += "<tr id=\"" + this.objName + (i+1) + "\">" + this.displayList + "</tr>";

            
        }
       



        //alert(JSON.stringify(this.purchaseItem));
        //alert("check final==" + JSON.stringify("{'objs':"+purchaseItems+"}"));

        $("#" + this.resValue).val(JSON.stringify(this.purchaseItem));
        $("#" + this.resMember).append(exRespi);
        this.bindEdit();
    }
    bindEdit() {
        let clsRef=this;
        //alert(clsRef.purchaseItem.tt.length);
        $('.editable').unbind('click');
        $(".editable").click(function (event) {
            if ($(this).children("input").length > 0)
                return false;

            
            var tdObj = $(this);
            let e_key = $(this).attr("e_key");
            let e_id = $(this).attr("e_id");

            var preText = tdObj.html();
            let inputObj = $("<input type='text' />");
            tdObj.html("");
            if (e_key === "gov_department") {
                inputObj = $("<select>" +
                    "<option value=\"\">Select</option>" +
                    "<option value=\"Free\">Free</option>" +
                    "<option value=\"Deendayal\">Dindayal</option>" +
                    "<option value=\"Pensioners\">Pensioners</option>" +
                    "<option value=\"MD\">MD</option>" +
                    "<option value=\"PhD\">PhD</option>" +
                    "<option value=\"Paid\">Paid</option>" +
                    "<option value=\"JYS\">JYS</option>" +
                    "</select> ");

                inputObj.width(tdObj.width())
                    .height(tdObj.height())
                    .css({ border: "0px", fontSize: "17px" })
                    .val(preText)
                    .appendTo(tdObj)
                    .trigger("focus")
                    .trigger("select");

                inputObj.keypress(function (event) {
                    //alert(event.which);
                    if (13 == event.which || 9 === event.which) { // press ENTER-key
                        var text = $(this).val();
                        tdObj.html(text);
                        //alert(text);
                        //editLoadDocumentTb(e_id, e_key, text);

                    }
                    else if (27 == event.which) {  // press ESC-key
                        tdObj.html(preText);
                    }
                });
            } else {
                inputObj.width(tdObj.width())
                    .height(tdObj.height())
                    .css({ border: "0px", fontSize: "17px" })
                    .val(preText)
                    .appendTo(tdObj)
                    .trigger("focus")
                    .trigger("select");

                inputObj.keyup(function (event) {
                    //alert(event.which);
                    if (13 == event.which || 9 === event.which) { // press ENTER-key
                        var text = $(this).val();
                        tdObj.html(text);
                        
                        //editLoadDocumentTb(e_id, e_key, text);

                    }
                    else if (27 == event.which) {  // press ESC-key
                        tdObj.html(preText);
                    }
                });
            }

          

            inputObj.click(function () {
                return false;
            });
        });
        for (let de = 0; de < this.purchaseItem.tt.length; de++) {
            if(this.canEdit===true){
                document.getElementById(this.objName + "_edit_" + (de + 1)).addEventListener("click", (e) => this.refEdit(e, (de)), false);
            }
            if(this.canDelete===true){
                document.getElementById(this.objName + "_de_" + (de + 1)).addEventListener("click", (e) => this.refDel(e, (de)), false);
            }
            
        }
    }

    editLoadDocumentTb(e_id, e_key, text){
        
        let clsItem=this;
        clsItem.purchaseItem.tt[e_id][e_key] = text;
        let exRate = parseFloat(clsItem.purchaseItem.tt[e_id]["rate"]).toFixed(2);
        let exQuantity = parseFloat(clsItem.purchaseItem.tt[e_id]["quantity"]).toFixed(2);
        let discPer = 0;
        if (clsItem.purchaseItem.tt[e_id]["discount_per"] !== "") {
            discPer = parseFloat(clsItem.purchaseItem.tt[e_id]["discount_per"]).toFixed(2);
        }
        let amount = exRate * exQuantity;
        if (discPer !== 0) {
            amount = (amount - (amount * discPer / 100)).toFixed(2);
        }
        clsItem.purchaseItem.tt[e_id]["amount"] = amount;
        clsItem.setExistingData();
    }

    editDataNew(piMembers,piHidMembers){
         //alert("i m in add java");
        
         for (var i = 0; i < piMembers.length; i++) {
             if (piMembers[i].hasAttribute("required_mg")) {
                 if (piMembers[i].value.trim() == "") {
                     alert(piMembers[i].getAttribute("required_mg")+" ==" +piMembers[i].value);
                     piMembers[i].focus();
                     return "0";
                 }
             }
             let memName = piMembers[i].id;
             if (piMembers[i].hasAttribute("rfnode")) {
                 memName = piMembers[i].getAttribute("rfnode");
             }
             if(piMembers[i].hasAttribute("val_memeber")){
                 document.getElementById(piMembers[i].getAttribute("val_memeber")).value=piMembers[i].value.trim();
             }
             if (piMembers[i].type == "select-one" || piMembers[i].type == "select-multiple") {
                 //purchaseItems[memName] = piMembers[i].value.trim();
                 //alert(memName+piMembers[i].value);
                 if(piMembers[i].hasAttribute("val_memeber")){
                     //alert("i m in tur");
                     this.purchaseItem.tt[this.editIndex][memName]=piMembers[i].options[piMembers[i].selectedIndex].text.trim();
                 }else{
                    this.purchaseItem.tt[this.editIndex][memName]=piMembers[i].value.trim();
                 }
                 
                 /* for assign display member */
                
 
             } else {
                //alert(memName);
                this.purchaseItem.tt[this.editIndex][memName] = piMembers[i].value.trim();
             }
         }
         for (var j = 0; j < piHidMembers.length; j++) {
             let memName = piHidMembers[j].id;
 
             if (piHidMembers[j].hasAttribute("required_mg")) {
                 if (piHidMembers[j].value.trim() == "") {
                     
                     alert(piHidMembers[j].getAttribute("required_mg"));
                     if (piHidMembers[j].hasAttribute("set_required_focus")){
                         let requiredFocusObj=document.getElementById(piHidMembers[j].getAttribute("set_required_focus"));
                         requiredFocusObj.focus();
                     }
                     
                     return "0";
                 }
             }
 
             if (piHidMembers[j].hasAttribute("rfnode")) {
                 memName = piHidMembers[j].getAttribute("rfnode");
             }
             //alert(piHidMembers[j].value.trim());
             this.purchaseItem.tt[this.editIndex][memName] = piHidMembers[j].value.trim();
         }
         //alert(this.purchaseItem.tt.length);
         //this.purchaseItem.tt.push(purchaseItems);
         this.setExistingData();
         
         if (typeof this.preSetInvoiceData === "function") {
             this.calcData();
         }
         if (typeof this.preEventSetData === "function") {
                this.preEventSetData();
         }

         if (this.editCompleteCBack!==""){
            $("#"+this.editCompleteCBack).modal('hide');
         }

         //this.clearFieldsviaClass();
    }

    setDataNew(piMembers, piHidMembers) {
        //alert("i m in add java");
        this.strList = "";
        this.displayList = "";
        let purchaseItems = {};
        for (var i = 0; i < piMembers.length; i++) {
            if (piMembers[i].hasAttribute("required_mg")) {
                if (piMembers[i].value.trim() == "") {
                    alert(piMembers[i].getAttribute("required_mg")+" ==" +piMembers[i].value);
                    piMembers[i].focus();
                    return "0";
                }
            }
            let memName = piMembers[i].id;
            if (piMembers[i].hasAttribute("rfnode")) {
                memName = piMembers[i].getAttribute("rfnode");
            }
            if(piMembers[i].hasAttribute("val_memeber")){
                document.getElementById(piMembers[i].getAttribute("val_memeber")).value=piMembers[i].value.trim();
            }
            if (piMembers[i].type == "select-one" || piMembers[i].type == "select-multiple") {
                //purchaseItems[memName] = piMembers[i].value.trim();
                //alert(memName+piMembers[i].value);
                if(piMembers[i].hasAttribute("val_memeber")){
                    //alert("i m in tur");
                    purchaseItems[memName]=piMembers[i].options[piMembers[i].selectedIndex].text.trim();
                }else{
                    purchaseItems[memName]=piMembers[i].value.trim();
                }
                
                /* for assign display member */
               

            } else {
                purchaseItems[memName] = piMembers[i].value.trim();
            }
        }
        for (var j = 0; j < piHidMembers.length; j++) {
            let memName = piHidMembers[j].id;

            if (piHidMembers[j].hasAttribute("required_mg")) {
                if (piHidMembers[j].value.trim() == "") {
                    
                    alert(piHidMembers[j].getAttribute("required_mg"));
                    if (piHidMembers[j].hasAttribute("set_required_focus")){
                        let requiredFocusObj=document.getElementById(piHidMembers[j].getAttribute("set_required_focus"));
                        requiredFocusObj.focus();
                    }
                    
                    return "0";
                }
            }

            if (piHidMembers[j].hasAttribute("rfnode")) {
                memName = piHidMembers[j].getAttribute("rfnode");
            }
            //alert(piHidMembers[j].value.trim());
            purchaseItems[memName] = piHidMembers[j].value.trim();
        }
        //alert(this.purchaseItem.tt.length);
        this.purchaseItem.tt.push(purchaseItems);
        this.setExistingData();
        if (typeof this.preSetInvoiceData === "function") {
            this.calcData();
        }
        if (typeof this.preEventSetData === "function") {
               this.preEventSetData();
        }
        this.clearFieldsviaClass();
    }

    setData(piMembers, piHidMembers) {
       
        this.strList = "";
        this.displayList = "";
        
        for (var i = 0; i < piMembers.length; i++) {
            if (this.strList != "") {
                this.strList += ",";
            }

            if (piMembers[i].hasAttribute("required_mg")) {
                if (piMembers[i].value.trim() == "") {
                    alert(piMembers[i].getAttribute("required_mg"));
                    piMembers[i].focus();
                    return "0";
                }
            }
            //alert(piMembers[i].type);
            if (piMembers[i].type == "select-one" || piMembers[i].type == "select-multiple") {
                this.displayList = this.displayList + "<td>" + piMembers[i].options[piMembers[i].selectedIndex].text.trim() + "</td>";
                this.strList += '"' + piMembers[i].id + '":' + '"' + piMembers[i].value.trim() + '"';
            } else {
                this.displayList = this.displayList + "<td>" + piMembers[i].value.trim() + "</td>";
                this.strList += '"' + piMembers[i].id + '":' + '"' + piMembers[i].value.trim() + '"';
            }
        }
        for (var j = 0; j < piHidMembers.length; j++) {
            
            if (this.strList != "") {
                this.strList += ",";
            }
            var checkStr = false;
            try {
                JSON.parse(piHidMembers[j].value.trim())
                checkStr = true;
            } catch (err) {
                checkStr = false;
            }
            if (checkStr) {
                if (piHidMembers[j].hasAttribute("haslist")) {
                    this.strList += '"' + piHidMembers[j].id + '":' + piHidMembers[j].value.trim();
                } else {
                    this.strList += '"' + piHidMembers[j].id + '":' + piHidMembers[j].value.trim();
                }
                
            } else {
                if (piHidMembers[j].hasAttribute("haslist")) {
                    //alert("i m in list== " + piHidMembers[j].value.trim());
                    this.strList += '"' + piHidMembers[j].id + '":'  + piHidMembers[j].value.trim();
                } else {
                    this.strList += '"' + piHidMembers[j].id + '":' + '"' + piHidMembers[j].value.trim() + '"';
                }
                
            }
            
        }
        this.strList = "[{" + this.strList + "}]";
        this.purchaseItem.tt.push(this.strList);
        //this.setExistingData();
        //add Delete
        let strEditDelete="";
        if(this.canEdit===true){
            strEditDelete="<a id=\"" + this.objName + "_edit_" + this.purchaseItem.tt.length + "\" ref_index=\""+ (this.purchaseItem.tt.length - 1) +"\" href=\"javascript:void(0)\"><i class=\"fa fa-pencil\" aria-hidden=\"true\"></i></a>";
        }
        if(this.canDelete===true){
            strEditDelete+="<a style=\"float:right\" id=\"" + this.objName + "_de_" + this.purchaseItem.tt.length + "\" ref_index=\""+ (this.purchaseItem.tt.length - 1) +"\" href=\"javascript:void(0)\"><i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i></a>";
        }

        this.displayList += "<td>"+
        strEditDelete+
        "</td>";

        if (typeof this.preSetInvoiceData === "function") {
            if ($("#amount").val()!=="")
            this.preSetInvoiceData($("#amount").val());
        }
        if (typeof this.preEventSetData === "function") {
                this.preEventSetData($("#fctmpt").val());
        }
            

        this.clearFieldsviaClass();
        return this.displayList;
    }
}
export default listData;
