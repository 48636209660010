import $, { extend } from 'jquery';
import React,{useEffect,useContext,useState} from 'react';
// import {GbSetGetPartyInfo,GbSetGetItemInfo,
//   GbSetSiteMaster,GbSetVehicleMaster} from '../cGlobal/cls_global';
import { GlobalContext } from '../context';
import {CallMapMethod_CBack,getAppUrl} from '../function_lib/common_lib';
import Select from 'react-select';
import axios from 'axios';
//import './comp_auto_complete.css';

// AutoComplete Item
export function ExtItemAutoComplete(props,autoList){
  $("#"+props.comp_id).autocomplete({
      
    source:autoList,
    select:function(event,ui){
     event.preventDefault();
     //alert(ui.item.menu);
     //let elementObj=event.target;
     event.target.value=ui.item.label;
     let vTargetObj=event.target.getAttribute("target_obj");
     document.getElementById(vTargetObj).value=btoa(ui.item.value);
     if(event.target.hasAttribute("display_stock")){
      let vObjShowAvailName=event.target.getAttribute("display_stock");
      if(ui.item.full_availability===null){
        ui.item.full_availability=0;
      }
      document.getElementById(vObjShowAvailName).value=ui.item.full_availability+" -"+ui.item.unit_name;
     }
     if(event.target.hasAttribute("display_stock_name")){
      let vObjStockName=event.target.getAttribute("display_stock_name");
      document.getElementById(vObjStockName).innerHTML =ui.item.label;
     }
    
     $("#rate").val(ui.item.item_rate);
     //alert(elementObj.getAttribute("target_obj"));
    
 },focus: function (event, ui) {
  event.preventDefault();
  let vTargetObj=event.target.getAttribute("target_obj");
  document.getElementById(vTargetObj).value="";
  

  //$(this).autocomplete('search', $("#txtSearchHead").val());
 },
 open: function( event, ui ) {
   let vTargetObj=event.target.getAttribute("target_obj");
   //alert(vTargetObj);
   document.getElementById(vTargetObj).value="";
 }
 }).data("ui-autocomplete")._renderItem = function (ul, item) {
  var newText = String(item.label).replace(
          new RegExp(this.term, "gi"),
          "<span class='ui-state-highlight'>$&</span>");
      //$("#ui-id-1").style
  return $("<li></li>")
      .data("item.autocomplete", item)
      .append("<div>" + newText + "</div>")
      .appendTo(ul);
 };
}

export function CompItem_Auto_Complete(props){
  
  const {gbStPartyInfo, setPartyInfo, gbStItemInfo, setItemInfo} =useContext(GlobalContext);
  const [compStParty, setStParty] = useState(null);
  const [compStItem,setStItem]=useState(null);
     
     useEffect(() => {
         // This code will run after the component has mounted
        
 const fetchData = async () => {
   if(gbStItemInfo===0 || gbStItemInfo===null){
     var map=[];
     map.push({name:"curl_type",value:"getPartynItemsMaster"});
     map.push({name:"res_func",value:"callBackrrr"});
     map.push({name:"local_token",value:"000"});
     map["res_func"]="callBackrrr";
     map["curl_type"]="getPartynItemsMaster";
     const apiUrl = getAppUrl()+"getPartynItemsMaster";
     //alert(apiUrl);
     const response = await axios.post(apiUrl, map);
     var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
     var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
     if(checkData!=="0"){
       let compMasterData=JSON.parse(checkData);
       ExtItemAutoComplete(props,compMasterData["item_master"]);
       setStParty(compMasterData["party_info"]);
       setStItem(compMasterData["item_master"]);
     }
   }else{
    ExtItemAutoComplete(props,gbStItemInfo);
   }
   
 
 }
         
         fetchData();
         
         
         // You can return a cleanup function if needed
         return () => {
           // This code will run when the component unmounts
           //console.log('Component unmounted');
         };
       }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting
     
      
 
     return(<>
     <input display_stock={props.display_stock} display_stock_name={props.display_stock_name} id={props.comp_id} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' placeholder={props.placeHolder} class={props.propClass}/>
     </>)
 }
//  end of AutoComplete Item
 

// PartyInfoAutoComplete           
export function ExtCompAutoComplete(props,autoList){
  //alert("i m in uatoco");
    $("#"+props.comp_id).autocomplete({
   
        source:function(request, response) {
          var matches = $.map(autoList, function(item) {
              if (item.label.toLowerCase().startsWith(request.term.toLowerCase())) {
                  return item;
              }
          });
          response(matches);},
        select:function(event,ui){
         event.preventDefault();
         //alert(ui.item.menu);
         //let elementObj=event.target;
         event.target.value=ui.item.label;
         let vTargetObj=event.target.getAttribute("target_obj");
         document.getElementById(vTargetObj).value=btoa(ui.item.value);
        //alert(JSON.stringify(ui.item));
         $("#contact_no").val(ui.item.contact_no);
         //alert(elementObj.getAttribute("target_obj"));
        
     },focus: function (event, ui) {
      event.preventDefault();
      let vTargetObj=event.target.getAttribute("target_obj");
      document.getElementById(vTargetObj).value="";
      
   
      //$(this).autocomplete('search', $("#txtSearchHead").val());
     },
     open: function( event, ui ) {
      //alert("i min auto");
       let vTargetObj=event.target.getAttribute("target_obj");
       //alert(vTargetObj);
       document.getElementById(vTargetObj).value="";
     }
     }).data("ui-autocomplete")._renderItem = function (ul, item) {
      var newText = String(item.label).replace(
              new RegExp(this.term, "gi"),
              "<span class='ui-state-highlight'>$&</span>");
          //$("#ui-id-1").style
      return $("<li></li>")
          .data("item.autocomplete", item)
          .append("<div>" + newText + "</div>")
          .appendTo(ul);
     };
}


export function Comp_Auto_Complete(props){
  
 const {gbStPartyInfo, setPartyInfo, gbStItemInfo, setItemInfo} =useContext(GlobalContext);
 //const [compStParty, setStParty] = useState(null);
 //const [compStItem,setStItem]=useState(null);
 //const [defVal,setDefVal]=useState("");

    
 
    useEffect(() => {
      
        // This code will run after the component has mounted
    //alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
  if(gbStPartyInfo===0 || gbStPartyInfo===null || gbStPartyInfo===""){
    var map=[];
    //alert("i m in sync");
    map.push({name:"curl_type",value:"getPartynItemsMaster"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:"000"});
    map["res_func"]="callBackrrr";
    map["curl_type"]="getPartynItemsMaster";
    const apiUrl = getAppUrl()+"getPartynItemsMaster";
    //alert(apiUrl);
    const response = await axios.post(apiUrl, map);
    var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
    var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
    if(checkData!=="0"){
      let compMasterData=JSON.parse(checkData);
      ExtCompAutoComplete(props,compMasterData["party_info"]);
      setPartyInfo(compMasterData["party_info"]);
      setItemInfo(compMasterData["item_master"]);
      //setStParty(compMasterData["party_info"]);
      //setStItem(compMasterData["item_master"]);
    }
  }else{
    ExtCompAutoComplete(props,gbStPartyInfo);
  }
  

}
        
        fetchData();
        
        
        // You can return a cleanup function if needed
        return () => {
          // This code will run when the component unmounts
          //console.log('Component unmounted');
        };
      }, []); // Pass an empty array as the second argument to run the effect only once after mounting
    
     //alert(props.propDefaultValue);

    return(<>
    <input id={props.comp_id} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' defaultValue={props.propDefaultValue} placeholder={props.placeHolder} class={props.propClass}/>
    </>)
}
//Enf of AutoComplete PartyInfo

// SiteMaster Global
export function ExtSiteCompAutoComplete(props,autoList){
  $("#"+props.comp_id).autocomplete({
    
      source:function(request, response) {
        var matches = $.map(autoList, function(item) {
            if (item.label.toLowerCase().startsWith(request.term.toLowerCase())) {
                return item;
            }
        });
        response(matches);},
      select:function(event,ui){
       event.preventDefault();
       //alert(ui.item.menu);
       //let elementObj=event.target;
       event.target.value=ui.item.label;
       let vTargetObj=event.target.getAttribute("target_obj");
       document.getElementById(vTargetObj).value=btoa(ui.item.value);
      
       //$("#contact_no").val(ui.item.contact_no);
       //alert(elementObj.getAttribute("target_obj"));
      
   },focus: function (event, ui) {
    event.preventDefault();
    let vTargetObj=event.target.getAttribute("target_obj");
    document.getElementById(vTargetObj).value="";
    
 
    //$(this).autocomplete('search', $("#txtSearchHead").val());
   },
   open: function( event, ui ) {
     let vTargetObj=event.target.getAttribute("target_obj");
     //alert(vTargetObj);
     document.getElementById(vTargetObj).value="";
   }
   }).data("ui-autocomplete")._renderItem = function (ul, item) {
    var newText = String(item.label).replace(
            new RegExp(this.term, "gi"),
            "<span class='ui-state-highlight'>$&</span>");
        //$("#ui-id-1").style
    return $("<li></li>")
        .data("item.autocomplete", item)
        .append("<div>" + newText + "</div>")
        .appendTo(ul);
   };
}


export function CompSite_Auto_Complete(props){

const {gbStSiteMaster, setSiteMaster} =useContext(GlobalContext);
const [compStSiteMaster, setStSiteMaster] = useState(null);

useEffect(() => {
      // This code will run after the component has mounted
  //alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
if(gbStSiteMaster===0 || gbStSiteMaster===null || gbStSiteMaster===""){
  var map=[];
  //alert("i m in sync");
  map.push({name:"curl_type",value:"get_site_master_auto"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:"000"});
  map["res_func"]="callBackrrr";
  map["curl_type"]="get_site_master_auto";
  const apiUrl = getAppUrl()+"get_site_master_auto";
  //alert(apiUrl);
  const response = await axios.post(apiUrl, map);
  var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
  var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
  if(checkData!=="0"){
    let compMasterData=JSON.parse(checkData);
    ExtSiteCompAutoComplete(props,compMasterData);
    setStSiteMaster(compMasterData);
    
  }
}else{
  ExtSiteCompAutoComplete(props,gbStSiteMaster);
}
}
      fetchData();
      // You can return a cleanup function if needed
      return () => {
        // This code will run when the component unmounts
        //console.log('Component unmounted');
      };
    }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting
  return(<>
  <input id={props.comp_id} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' placeholder={props.placeHolder} class={props.propClass}/>
  </>)
}
//End of SiteMaster


// SiteVehicle Global
export function ExtVehicleCompAutoComplete(props,autoList){
  $("#"+props.comp_id).autocomplete({
    
      source:function(request, response) {
        var matches = $.map(autoList, function(item) {
            if (item.label.toLowerCase().startsWith(request.term.toLowerCase())) {
                return item;
            }
        });
        response(matches);},
      select:function(event,ui){
       event.preventDefault();
       //alert(ui.item.menu);
       //let elementObj=event.target;
       event.target.value=ui.item.label;
       let vTargetObj=event.target.getAttribute("target_obj");
       document.getElementById(vTargetObj).value=btoa(ui.item.value);
      
       $("#mfg").val(ui.item.vehicle_type);
       //alert(elementObj.getAttribute("target_obj"));
      
   },focus: function (event, ui) {
    event.preventDefault();
    let vTargetObj=event.target.getAttribute("target_obj");
    document.getElementById(vTargetObj).value="";
    
 
    //$(this).autocomplete('search', $("#txtSearchHead").val());
   },
   open: function( event, ui ) {
     let vTargetObj=event.target.getAttribute("target_obj");
     //alert(vTargetObj);
     document.getElementById(vTargetObj).value="";
   }
   }).data("ui-autocomplete")._renderItem = function (ul, item) {
    var newText = String(item.label).replace(
            new RegExp(this.term, "gi"),
            "<span class='ui-state-highlight'>$&</span>");
        //$("#ui-id-1").style
    return $("<li></li>")
        .data("item.autocomplete", item)
        .append("<div>" + newText + "</div>")
        .appendTo(ul);
   };
}


export function CompVehicle_Auto_Complete(props){

const {gbStVehicleMaster, setVehicleMaster} =useContext(GlobalContext);
const [compStVehicleMaster, setStVehicleMaster] = useState(null);

useEffect(() => {
      // This code will run after the component has mounted
  //alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
if(gbStVehicleMaster===0 || gbStVehicleMaster===null || gbStVehicleMaster===""){
  var map=[];
  //alert("i m in sync");
  map.push({name:"curl_type",value:"get_vehicle_master_auto"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:"000"});
  map["res_func"]="callBackrrr";
  map["curl_type"]="get_vehicle_master_auto";
  const apiUrl = getAppUrl()+"get_vehicle_master_auto";
  //alert(apiUrl);
  const response = await axios.post(apiUrl, map);
  var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
  var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
  if(checkData!=="0"){
    let compMasterData=JSON.parse(checkData);
    ExtVehicleCompAutoComplete(props,compMasterData);
    setStVehicleMaster(compMasterData);
    
  }
}else{
  ExtVehicleCompAutoComplete(props,gbStVehicleMaster);
}
}
      fetchData();
      // You can return a cleanup function if needed
      return () => {
        // This code will run when the component unmounts
        //console.log('Component unmounted');
      };
    }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting
  return(<>
  
  <input id={props.comp_id} defaultValue={props.defaultValue} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' placeholder={props.placeHolder} class={props.propClass}/>
  </>)
}
//End of VehicleMaster

//Non InventoryLedgerComp

export function Comp_Inventory_Ledger(props){

  const {gbStInventoryLedger, setInventoryLedger} =useContext(GlobalContext);
  const [compStInventoryLedger, setStInventoryLedger] = useState(null);
  
  useEffect(() => {
        // This code will run after the component has mounted
    //alert("hello"+gbStPartyInfo+"noon");   
  const fetchData = async () => {
  if(gbStInventoryLedger===0 || gbStInventoryLedger===null || gbStInventoryLedger===""){
    var map=[];
    //alert("i m in sync");
    map.push({name:"curl_type",value:"getCatUnitMaster"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:"000"});
    map["res_func"]="callBackrrr";
    map["curl_type"]="getCatUnitMaster";
    const apiUrl = getAppUrl()+"getCatUnitMaster";
    //alert(apiUrl);
    const response = await axios.post(apiUrl, map);
    var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
    var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
    if(checkData!=="0"){
      let compMasterData=JSON.parse(checkData);
      setStInventoryLedger(compMasterData["ls_inventory_ledger"]);
      setInventoryLedger(compMasterData["ls_inventory_ledger"])
    }
  }else{
   // ExtVehicleCompAutoComplete(props,gbStVehicleMaster);
  }
  }
        fetchData();
        // You can return a cleanup function if needed
        return () => {
          // This code will run when the component unmounts
          //console.log('Component unmounted');
        };
      }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting
    return(<>
    {/* <GbSetVehicleMaster getSet="set" lsVehicleMaster={compStVehicleMaster}/> */}
    <select id={props.comp_id} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' placeholder={props.placeHolder} class={props.propClass}>
      <option value={"0"}>-Ledger-</option>
      {gbStInventoryLedger!==null?gbStInventoryLedger.map((item,index)=>{
        return <option value={btoa(item["id"])}>{item["name"]}</option>
      }):''}
    </select>
    {/* <input id={props.comp_id} name={props.comp_name} target_obj={props.target_obj} autoComplete='off' placeholder={props.placeHolder} class={props.propClass}/> */}
    </>)
  }



  //============================ New AutoComplete Logical ex


export function CompEmployee_Auto_Complete_New(props){
  const {gbStEmployee,setEmployeeMaster}=useContext(GlobalContext);
  const [selectedOption, setSelectedOption]=useState(null);
  
  const handleChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(()=>{

     
    // This code will run after the component has mounted
//alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
  if(gbStEmployee===0 || gbStEmployee===null || gbStEmployee===""){
  var map=[];
  //alert("i m in sync");
  map.push({name:"curl_type",value:"get_user_info"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:"000"});
  map["res_func"]="callBackrrr";
  map["curl_type"]="get_user_info";
  const apiUrl = getAppUrl()+"get_user_info";
  //alert(apiUrl);
  const response = await axios.post(apiUrl, map);
  var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
  var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
  if(checkData!=="0"){
    let compMasterData=JSON.parse(checkData);
    //ExtCompAutoComplete(props,compMasterData["party_info"]);
    //alert(JSON.stringify(compMasterData["item_master"]));
    setEmployeeMaster(compMasterData);
    
  }
  }else{
  //ExtCompAutoComplete(props,gbStPartyInfo);
  }
  
  
  }
      
      fetchData();
      
      
      // You can return a cleanup function if needed
      return () => {
        // This code will run when the component unmounts
        //console.log('Component unmounted');
      };

  },[props.autoList])


  return(<><Select className={props.propClass}
    //inputId={props.target_obj}
     id={props.comp_id}
     name={props.comp_name}
    target_obj={props.target_obj}
    value={selectedOption}
    onChange={handleChange}
    options={gbStEmployee}
    getOptionLabel={(option) => option.contact_person}
    getOptionValue={(option) => btoa(option.id)}
    placeholder={props.placeHolder}
    isClearable
  />
  <input type="hidden" name={props.target_obj} id={props.target_obj} 
  value={selectedOption ? btoa(selectedOption.id) : ''}
  />
  </>)
}

export function CompItem_Auto_Complete_New(props){
  const {gbStPartyInfo, setPartyInfo, gbStItemInfo, setItemInfo} =useContext(GlobalContext);
  const [selectedOption, setSelectedOption] = useState(null);

  

  const handleChange = (option) => {
if(option!==null){
  if(typeof props.display_stock!=="undefined"){
    if(option.full_availability===null){
      option.full_availability=0;
    }
    document.getElementById(props.display_stock).value=option.full_availability+" -"+option.unit_name;
  }
  if(typeof props.display_stock_name!=="undefined"){
    document.getElementById(props.display_stock_name).innerHTML =option.label;
  }
}
    

    setSelectedOption(option);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      //position: 'fixed',  // Change the position to static
      // height:'30px'
    }),
    // menuList: (provided) => ({
    //   ...provided,
    //   position: "fixed",  // Adjust padding if necessary
    // }),
    // input: (provided, state) => ({
    //   ...provided,
    //   height: '10px',
    // }),
    control: (provided, state) => ({
      ...provided,
      // height: '10px',
      width:'200px',
    }),
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   height: '30px',
    //   padding: '0 6px'
    // }),
  };

  useEffect(() => {
    //alert("i m in use effect "+props.defaultValue);
    if(typeof props.defaultValue!=="undefined" && props.defaultValue!==null){
      setSelectedOption({"value":atob(props.defaultValue),"label":props.defaultLabel});
    }else{
      setSelectedOption(null);
    }
      
    // This code will run after the component has mounted
//alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
if((gbStPartyInfo===0 || gbStPartyInfo===null || gbStPartyInfo==="")|| props.setData===true){
var map=[];
//alert("i m in sync");
map.push({name:"curl_type",value:"getPartynItemsMaster"});
map.push({name:"res_func",value:"callBackrrr"});
map.push({name:"local_token",value:"000"});
map["res_func"]="callBackrrr";
map["curl_type"]="getPartynItemsMaster";
const apiUrl = getAppUrl()+"getPartynItemsMaster";
//alert(apiUrl);
const response = await axios.post(apiUrl, map);
var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
if(checkData!=="0"){
  let compMasterData=JSON.parse(checkData);
  //ExtCompAutoComplete(props,compMasterData["party_info"]);
  //alert(JSON.stringify(compMasterData["item_master"]));
  setPartyInfo(compMasterData["party_info"]);
  setItemInfo(compMasterData["item_master"]);
}
}else{
//ExtCompAutoComplete(props,gbStPartyInfo);
}


}
    
    fetchData();
    
    
    // You can return a cleanup function if needed
    return () => {
      // This code will run when the component unmounts
      //console.log('Component unmounted');
    };
  }, [props.autoList,props.defaultValue]); // Pass an empty array as the second argument to run the effect only once after mounting
if(props.setData!==true){
  return(<><Select 
   className="my-select-item"
   styles={customStyles}
     id={props.comp_id}
     name={props.comp_name}
    target_obj={props.target_obj}
    value={selectedOption}
    onChange={handleChange}
    options={gbStItemInfo}
    getOptionLabel={(option) => option.label}
    getOptionValue={(option) => btoa(option.value)}
    placeholder={props.placeHolder}
    isClearable
  />
  <input {...(props.required_mg !== undefined ? { required_mg: props.required_mg } : {})} className={props.propClassHid} type="hidden" name={props.target_obj} id={props.target_obj} 
  defaultValue={selectedOption ? btoa(selectedOption.value) : ''}
/>
{typeof  props.comp_sel_text!=="undefined"?<input  required_mg={props.required_mg}  className={props.propClass} type="hidden" name={props.comp_sel_text} id={props.comp_sel_text}
defaultValue={selectedOption?selectedOption.label:''}
 />:''}
  </>)
}else{
  return(<></>);
}

}


export function Comp_Auto_Complete_New(props){
  const {gbStPartyInfo, setPartyInfo, gbStItemInfo, setItemInfo} =useContext(GlobalContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (option) => {
    setSelectedOption(option);
    //alert(JSON.stringify(option));
    //onChange(option);
  };

  useEffect(() => {
      
    // This code will run after the component has mounted
//alert("hello"+gbStPartyInfo+"noon");   
const fetchData = async () => {
  //alert(props.setData);
if((gbStPartyInfo===0 || gbStPartyInfo===null || gbStPartyInfo==="") || props.setData===true){
var map=[];
//alert("i m in sync");
map.push({name:"curl_type",value:"getPartynItemsMaster"});
map.push({name:"res_func",value:"callBackrrr"});
map.push({name:"local_token",value:"000"});
map["res_func"]="callBackrrr";
map["curl_type"]="getPartynItemsMaster";
const apiUrl = getAppUrl()+"getPartynItemsMaster";
//alert(apiUrl);
const response = await axios.post(apiUrl, map);
var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
if(checkData!=="0"){
  let compMasterData=JSON.parse(checkData);
  //ExtCompAutoComplete(props,compMasterData["party_info"]);
  setPartyInfo(compMasterData["party_info"]);
  setItemInfo(compMasterData["item_master"]);
}
}else{
//ExtCompAutoComplete(props,gbStPartyInfo);
}


}
    
    fetchData();
    
    
    // You can return a cleanup function if needed
    return () => {
      // This code will run when the component unmounts
      //console.log('Component unmounted');
    };
  }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting
if (props.setData!==true){
  return(<><Select 
    //inputId={props.target_obj}
     id={props.comp_id}
     name={props.comp_name}
    target_obj={props.target_obj}
    value={selectedOption}
    onChange={handleChange}
    options={gbStPartyInfo}
    getOptionLabel={(option) => option.label}
    getOptionValue={(option) => btoa(option.value)}
    placeholder={props.placeHolder}
    isClearable
  />
  <input className={props.propClass} type="hidden" name={props.target_obj} id={props.target_obj} 
  value={selectedOption ? btoa(selectedOption.value) : ''}
  />
  {typeof  props.comp_sel_text!=="undefined"?<input className={props.propClass} type="hidden" name={props.comp_sel_text} id={props.comp_sel_text}
value={selectedOption?selectedOption.label:''}
 />:''}
  </>)
}else{
  return(<></>)
}

}
  

export function CompSite_Auto_Complete_New(props){

  const {gbStSiteMaster, setSiteMaster} =useContext(GlobalContext);
  const [selectedOption, setSelectedOption] = useState(null);
  
  useEffect(() => {
    if(typeof props.defaultValue!=="undefined" && props.defaultValue!==null){
      setSelectedOption({"value":atob(props.defaultValue),"label":props.defaultLabel});
    }else{
      setSelectedOption(null);
    }
        // This code will run after the component has mounted
    //alert("hello"+gbStPartyInfo+"noon");   
  const fetchData = async () => {
  if(gbStSiteMaster===0 || gbStSiteMaster===null || gbStSiteMaster===""){
    var map=[];
    //alert("i m in sync");
    map.push({name:"curl_type",value:"get_site_master_auto"});
    map.push({name:"res_func",value:"callBackrrr"});
    map.push({name:"local_token",value:"000"});
    map["res_func"]="callBackrrr";
    map["curl_type"]="get_site_master_auto";
    const apiUrl = getAppUrl()+"get_site_master_auto";
    //alert(apiUrl);
    const response = await axios.post(apiUrl, map);
    var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
    var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
    if(checkData!=="0"){
      let compMasterData=JSON.parse(checkData);
      //ExtSiteCompAutoComplete(props,compMasterData);
      setSiteMaster(compMasterData);
    }
  }else{
    //ExtSiteCompAutoComplete(props,gbStSiteMaster);
  }
  }
        fetchData();
        // You can return a cleanup function if needed
        return () => {
          // This code will run when the component unmounts
          //console.log('Component unmounted');
        };
      }, [props.autoList]); // Pass an empty array as the second argument to run the effect only once after mounting

      const handleChange = (option) => {
        setSelectedOption(option);
        //alert(JSON.stringify(option));
        //onChange(option);
      };
    return(
    <><Select 
  //inputId={props.target_obj}
   id={props.comp_id}
   name={props.comp_name}
  target_obj={props.target_obj}
  value={selectedOption}
  onChange={handleChange}
  options={gbStSiteMaster}
  getOptionLabel={(option) => option.label}
  getOptionValue={(option) => btoa(option.value)}
  placeholder={props.placeHolder}
  isClearable
/>
<input className={props.propClassHid} type="hidden" name={props.target_obj} id={props.target_obj} 
value={selectedOption ? btoa(selectedOption.value) : ''}
/>
{typeof  props.comp_sel_text!=="undefined"?<input className={props.propClass} type="hidden" name={props.comp_sel_text} id={props.comp_sel_text}
value={selectedOption?selectedOption.label:''}
 />:''}
    </>)
  }


  export function Comp_Inventory_Ledger_New(props){

    const {gbStInventoryLedger, setInventoryLedger} =useContext(GlobalContext);
    const [selectedOption, setSelectedOption] = useState(null);
    //alert("check ledger=="+props.defaultSelected);
    //alert(JSON.stringify(gbStInventoryLedger));
    useEffect(() => {
      if(gbStInventoryLedger!==null){
        if(typeof props.defaultSelected!=="undefined"){
          //alert("i m in set state "+JSON.stringify(props.defaultSelected));
          setSelectedOption(props.defaultSelected);
        }
        
      }
     
          // This code will run after the component has mounted
      //alert("hello"+gbStPartyInfo+"noon");   
    const fetchData = async () => {
    if(gbStInventoryLedger===0 || gbStInventoryLedger===null || gbStInventoryLedger===""){
      var map=[];
      //alert("i m in sync");
      map.push({name:"curl_type",value:"getCatUnitMaster"});
      map.push({name:"res_func",value:"callBackrrr"});
      map.push({name:"local_token",value:"000"});
      map["res_func"]="callBackrrr";
      map["curl_type"]="getCatUnitMaster";
      const apiUrl = getAppUrl()+"getCatUnitMaster";
      //alert(apiUrl);
      const response = await axios.post(apiUrl, map);
      var checkData=$($($.parseHTML(response.data)).filter("#resP")).text();
      var checkMsg=$($($.parseHTML(response.data)).filter("#resPS")).text();
      if(checkData!=="0"){
        let compMasterData=JSON.parse(checkData);
        setInventoryLedger(compMasterData["ls_inventory_ledger"])
      }
    }else{
     // ExtVehicleCompAutoComplete(props,gbStVehicleMaster);
    }
    }
          fetchData();
          // You can return a cleanup function if needed
          return () => {
            // This code will run when the component unmounts
            //console.log('Component unmounted');
          };
        }, [props.autoList,props.defaultSelected]); // Pass an empty array as the second argument to run the effect only once after mounting
        
        const handleChange = (option) => {
          setSelectedOption(option);
          //alert(JSON.stringify(option));
          //onChange(option);
        };
     return(
    <><Select className={props.propClass}
  //inputId={props.target_obj}
   id={props.comp_id}
   name={props.comp_name}
  target_obj={props.target_obj}
  value={selectedOption}
  onChange={handleChange}
  options={gbStInventoryLedger}
  getOptionLabel={(option) => option.name}
  getOptionValue={(option) => btoa(option.id)}
  placeholder={props.placeHolder}
  defaultValue={selectedOption}
  isClearable
/>
<input type="hidden" name={props.target_obj} id={props.target_obj} 
value={selectedOption ? btoa(selectedOption.id) : ''}
/>
    </>)
     
    }

// setAutoComponettoList


// ============================End of New Auto Complete


export default Comp_Auto_Complete;