import $, { extend } from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,
  TableDatePicker,setKeyUpMapp,setKeyUpMapp_CBack,isCheckFloat} from '../function_lib/common_lib';
import {tmpAutoList} from '../function_lib/city_india';
import {GbSetGetPartyInfo,GbSetGetItemInfo} from '../cGlobal/cls_global';
import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import listData from '../function_lib/customeData';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import defaultPic from '../assets/images/9.png';
import Comp_Add_Trans_Item from '../components/comp_add_trans_item';
import Comp_View_Payment_Breakup from '../components/comp_view_payment_breakup';
import Comp_View_Invoice_Breakup from '../components/comp_view_invoice_breakup';
import Pay_Old_Dues from '../components/pay_old_dues';
import {Comp_Auto_Complete,CompSite_Auto_Complete,CompVehicle_Auto_Complete,
    CompItem_Auto_Complete
} from '../components/comp_auto_complete';
import Comp_Import_Crushing from '../components/comp_import_crushing';
import {Link} from 'react-router-dom';
//import Wa_Credit_Balance_Comp from '../components/wa_credit_balance';
//import 'select2/dist/js/select2.full.min.js';
//import 'select2/dist/css/select2.min.css';

let loginUser="";
let userObject="";
let companyName="";
let clsCreateBilling;

let rf="0";

let _partyInfoList=[];
let _itemInfoList=[];
class Create_Crushing_Bill extends React.Component{

  // some imp lib assoc
  
  //end of imp lib assoc


  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        

        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stListClassFee:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stListAccLedger:[],stListStudent:[],
      stStudentInvoice:[],stStuFeeSubmitted:[],stStuOldDues:[],
    stStuPaymentBreakdown:[],stStuInvoiceBreakdown:[],stStudentId:0,
  stListParty:[],stListProduct:[]};

      this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      clsCreateBilling=this;
      }
      
// getMasters(){
//     var map=[];
//     map.push({name:"curl_type",value:"getPartynItemsMaster"});
//     map.push({name:"res_func",value:"callBackrrr"});
//     map.push({name:"local_token",value:userObject[0].app_user_id});
//     map["res_func"]="callBackrrr";
//     map["curl_type"]="getPartynItemsMaster";
//     CallMapMethod_CBack(clsCreateBilling,map,function(data,msg){
//       // alert(data);
//       // return "";
//         if(data!=="0" && data!==""){
//             let listData=JSON.parse(data);
//             var strData="";
//             //GbSetGetPartyInfo(listData["party_info"],"set");
//             //GbSetGetItemInfo(listData["item_master"],"set");
            
//             //alert(JSON.stringify(listData.party_info));
//             clsCreateBilling.setState({stListParty:listData["party_info"],stListProduct:listData["item_master"]},()=>{
//               clsCreateBilling.forceUpdateHandler();
//                 //locCityList=clsSubmitFee.stListParty;
               
//                 $('#ser_party_info').autocomplete('option', 'source', Object.values(clsCreateBilling.state.stListParty));
//                 $('#ser_item_id').autocomplete('option', 'source', Object.values(clsCreateBilling.state.stListProduct));
//             })
//           }
//     });
//   }



      
  okSuccEntryLedg(data,smg){
        if(data==="001"){
          alert("Record Saved Successfully");
          window.location.reload();
          //Custom_Alert("Successful","Record Added Successfully",true);
          //clsCategoryMaster.getSessionYear();
          //$("#frmCreate").find("input[type=text]").val("");
        }else{
          alert(smg);
          //Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }


      setImportItemToBill(lsItem){
        //alert(JSON.stringify(lsItem));
        clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt=[];
        for(let i=0;i<lsItem.length;i++){
          let exListItems={};
          exListItems["ex_date"]=lsItem[i]["Date"];
          exListItems["ser_item_id"]=lsItem[i]["Item"];
          exListItems["ser_unit_id"]=lsItem[i]["Item Unit"];
          exListItems["quantity"]=lsItem[i]["Quantity"];
          exListItems["rate"]=lsItem[i]["Rate"];
          exListItems["amount"]=lsItem[i]["Sub Total"];
          exListItems["net_amount"]=lsItem[i]["Amount"];
          exListItems["bags"]=lsItem[i]["Bags"];
          exListItems["package"]=lsItem[i]["Pack"];
          exListItems["free_goods"]=lsItem[i]["free_goods"];
          exListItems["col2"]=lsItem[i]["col2"]; //royalty no
          exListItems["col1"]=lsItem[i]["col1"];
          exListItems["exp"]=lsItem[i]["exp"];
          exListItems["mfg"]=lsItem[i]["mfg"];
          exListItems["make"]=lsItem[i]["make"];
          exListItems["batch"]=lsItem[i]["batch"];
          exListItems["ser_sitename"]=lsItem[i]["site_name"]
          //hidden fields
          exListItems["item_id"]=btoa(lsItem[i]["item_id"]);
          exListItems["unit_id"]=btoa(lsItem[i]["unit_id"]);
          exListItems["site_id"]=btoa(lsItem[i]["site_id"]);
          exListItems["reference_invoice_id"]=btoa(lsItem[i]["purchase_item_id"]);
          exListItems["ex_invoice_id"]=btoa(lsItem[i]["invoice_id"]);

          clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt.push(exListItems);
        }
        clsCreateBilling.clsBtnBreakUpsClick.setExistingData();
        clsCreateBilling.clsBtnBreakUpsClick.preEventSetData();
      }
      
componentDidMount(){
  // _partyInfoList=GbSetGetPartyInfo([],"get");
  // _itemInfoList=GbSetGetItemInfo([],"get");
  
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
//this.getMasters();

// product_add
this.clsBtnBreakUpsClick =new listData("_btnFeeBreakdown", "hellow");
this.clsBtnBreakUpsClick.canEdit=false;
this.clsBtnBreakUpsClick.canDelete=false;
this.clsBtnBreakUpsClick.setOnChangeEvent("fee_breakdown",this.clsBtnBreakUpsClick);
this.clsBtnBreakUpsClick.preEventSetData=function setTotalAmount(){
  let totalAmount=0;
  for(let i=0;i<clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt.length;i++){
    totalAmount+=parseFloat(clsCreateBilling.clsBtnBreakUpsClick.purchaseItem.tt[i]["net_amount"]);
  }
  document.getElementById("inv_amount").value=totalAmount;
  clsCreateBilling.calculateDiscNetAmount("inv_discount_per");
  //document.getElementById("inv_net_amount").value=totalAmount;
}

//payment breakdown
this.clsBtnPaymentBreadownClick=new listData("_btnPaymentBreakdown","hellow");
this.clsBtnPaymentBreadownClick.preEventSetData=function setPaymentTotal(){
}

setKeyUpMapp_CBack(this,"_keyupcustom",function cBack(event){
  //alert("i m in keyup");
  //alert(event.target.name);
  if(!event.target.hasAttribute("tag_jobject")){
    return;
  }
  let getTagTargetValue=event.target.getAttribute("tag_jobject");
  
  let objTargetVal=JSON.parse(getTagTargetValue);
  let objInput=event.target;

  
  //alert(JSON.stringify(objTargetVal));
  if(objTargetVal["t_type"]==="discount"){
    let objSource=document.getElementById(objTargetVal["t_source"]);
    let objPerValue=document.getElementById(objTargetVal["t_per_val"]);
    let objResult=document.getElementById(objTargetVal["t_result"]);

    //alert(objPer.value);
    if(objInput.value.trim()===""){
      objInput.value="0";
    }
    
    if(isCheckFloat(objSource.value) && isCheckFloat(objInput.value)){
      objPerValue.value = (objSource.value * objInput.value / 100).toFixed(2);
      objResult.value=objSource.value-objPerValue.value;

    }




  }
})

}

calculateDiscNetAmount(eventObjId){
  let discountPer=0;
  let discountAmt=0;
 
  if(document.getElementById(eventObjId)){
    //alert("i m in object");
    let eventObject=document.getElementById(eventObjId);
    let getTagTargetValue=eventObject.getAttribute("tag_jobject");
    //alert(getTagTargetValue);
    let objTargetVal=JSON.parse(getTagTargetValue);
    let objInput=eventObject;
  
    
    //alert(JSON.stringify(objTargetVal));
    if(objTargetVal["t_type"]==="discount"){
      let objSource=document.getElementById(objTargetVal["t_source"]);
      let objPerValue=document.getElementById(objTargetVal["t_per_val"]);
      let objResult=document.getElementById(objTargetVal["t_result"]);
  
      //alert(objPer.value);
      if(objInput.value.trim()===""){
        objInput.value="0";
      }
      
      if(isCheckFloat(objSource.value) && isCheckFloat(objInput.value)){
        objPerValue.value = (objSource.value * objInput.value / 100).toFixed(2);
        objResult.value=objSource.value-objPerValue.value;
  
      }
    }
  }
 }
 
oldDues_okSucc(data,msg){
  
  if(data==="001"){
    clsCreateBilling.getStudentFeenDues();
    alert("Old Due Submitted Successfully");
  }
}


      render(){
        
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-1">
  
<div className="row">
    
      <div className="col-md-12 col-sm-12">
         
      <div class="card card-secondary">
              {/* <!-- /.card-header --> */}
           <div class="card-header">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>{"Create Crushing Bill"}</b></h3>
                <Link className="btn btn-sm btn-danger float-right" to={"../view_crushing_bills"}>{"View Curshing Bills"}</Link>
                {/* <a className="btn btn-sm btn-danger float-right" href={"../View_Billing_Truck_Out"}></a> */}
              </div>
            {/* form data  */}
            <form class="form-group" id="frmFeeSubmission" name="frmFeeSubmission" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value={btoa("okSuccEntryLedg")} />
                <input type="hidden" name="curl_type" id="curl_type" value={btoa("ledger_fuel_out")} />
                <input type="hidden" name="m_type" id="m_type" value={btoa("ledger_fuel_out")} />
                <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                <input type="hidden" name="year_id" id="year_id" value={btoa("1")}/>
                <input type="hidden" name="invoice_ledger_id" id="invoice_ledger_id" value={btoa("12")}/>
                <input type="hidden" name="acc_inv_tran" id="acc_inv_tran" value={"1"}/>
                <input type="hidden" id="ex_date" name="ex_date" class="piMember miMember" value={$("#date").val()}/>
            <div class="card-body">           
            <div className="row">
            
            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="date" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Customer Name</label>
                                            <div className="input-group input-group-sm">
                                              <Comp_Auto_Complete comp_id={"ser_party_info"} comp_name={"ser_party_info"} target_obj={"party_info_id"} autoList={this.state.stListParty} placeHolder={"Customer Name"} propClass={"form-control form-control-sm required"} />
                                              {/* <input type="text" id="ser_party_info" name="ser_party_info" 
                                              class="form-control _setSearchKey" target_obj="party_info_id"  placeholder="Customer Name" autoComplete='off' 
                                              /> */}
                                              <input validate_to="ser_party_info" class="required" type="hidden" id="party_info_id" name="party_info_id" value=""/>
                                            
                                            </div>
                                        </div>
                                  </div>
            <div className="col-lg-2 col-md-2">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Contact No</label>
                                            <div className="input-group input-group-sm">
                                             <input type="text" name="contact_no" id="contact_no" class="form-control"/>
                                            </div>
                                        </div>
                                  </div>
                                  
            <div class="col-lg-2 col-md-2">
            <a type="button" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#divImportCrushing" data-target="#divImportCrushing" data-toggle="modal" class="btn btn-sm btn-danger float-right" id="popAddItem" value="add">ImportChallan</a>

            </div>
            </div>
{/* end of first row */}
{/* table fee Breakdown */}
<div class="row">

<table class="table table-bordered table-sm">
                                        <thead>
                                        <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                <th>Date</th>
                                                <th>Item</th>
                                                <th>Unit</th>
                                                <th>RST No.</th>
                                                <th>Gross Wt.(KG)</th>
                                                <th>Tare Wt.(KG)</th>
                                                <th>Net Wt.(KG)</th>
                                                <th>Net Wt.(Ton)</th>
                                                <th>Vehicle No</th>
                                                <th>Vehicle Details</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                                <th style={{"width":"40px"}}>
                                                  {/* <a type="button" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#divAddBlastingItem" data-target="#divAddBlastingItem" data-toggle="modal" class="btn btn-sm btn-danger float-right" id="popAddItem" value="add">Add</a> */}
                                                  </th>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    <CompItem_Auto_Complete  comp_id={"ser_item_id"} comp_name={"ser_item_id"} target_obj={"item_id"} autoList={[]} placeHolder={"Product/Item"} propClass={"form-control form-control-sm piMember miMember"} />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                  <select id="ser_unit_id" name="ser_unit_id" val_memeber="unit_id" class="form-control piMember miMember">
                                                  {/* <option value={btoa("2")}>METER</option> */}
                                                  <option value={btoa("3")}>TON</option>
                                                    <option value={btoa("4")}>NO</option>
                                                  </select>
                                                  </div>
                                                  </td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="col1" name="col1" required_mg="RST No. Required"  class="form-control  piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="bags" name="bags" required_mg="Gross Weight (Ton) Required" relate_control="package" output_to="free_goods" class="form-control cd_input_change_sub piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="package" name="package" required_mg="Tyre Weight Required" relate_control="bags" output_to="free_goods" class="form-control cd_input_change_sub piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="free_goods" name="free_goods" required_mg="Net weight Required" output_to="quantity" class="form-control cd_input_change_meter_ton piMember miMember" /></div></td>
                                                <td><div class="input-group input-group-sm"><input type="text" id="quantity" name="quantity" required_mg="Quantity Required" class="form-control cd_input_change11 piMember miMember" /></div></td>
                                                
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    {/* <input type="text" id="exp" name="exp" class="form-control piMember miMember" /> */}
                                                    <CompVehicle_Auto_Complete comp_id={"exp"} comp_name={"exp"} target_obj={"vehicle_id"} autoList={[]} placeHolder={"Vehicle No"} propClass={"form-control form-control-sm piMember miMember"} />
                                                        <input type="hidden" id="vehicle_id" name="vehicle_id" />

                                                        <input type="hidden" readOnly={"true"} id="amount" name="amount" class="form-control piHidMember miMember" value={"0"} />
                                                        <input readOnly={"true"} type="hidden" id="net_amount" name="net_amount" relate_control="install_amount" output_to="total_amount" class="form-control piHidMember miMember" value={"0"} />
                                                        <input type="hidden" id="item_id" name="item_id" required_mg={"Select Item properly"} set_required_focus={"ser_item_id"} class="piHidMember miMember" value={""} />
                                                        <input type="hidden" id="unit_id" name="unit_id" class="piHidMember miMember" value="0" />
                                                    </div>
                                                </td>
                                                <td><div class="input-group input-group-sm">
                                                  <input type="text" id="mfg" name="mfg" class="form-control piMember miMember" />
                                                 
                                                  </div></td>
                                                  <td><div class="input-group input-group-sm"><input type="text" id="rate" name="rate" required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                  <td><div class="input-group input-group-sm"><input type="text" readOnly={"true"} id="amount" name="amount" required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                {/* <td><div class="input-group input-group-sm"></div></td> */}

                                                <td>
                                                  <input type="button" class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_ItemList" resname="tbItemList" id="_btnFeeBreakdown" value="add" />
                                                  </td>
                                            </tr>
                                        </thead>
                                        <tbody id="tbItemList">
                                        </tbody>
                                    </table>
                                    <input type="hidden" id="fctmpt" name="fctmpt" value="0" />
                                    <input type="hidden" id="p_ItemList" name="p_ItemList" value="" />
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                    
                                    </div>
                                    <div class="col-md-10">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr style={{"background-color":"#FC766AFF"}}>
                                                    <th>Amount</th>
                                                    <th>Invoice Discount(%)</th> 
                                                    <th>Discount Amount</th> 
                                                    <th>Invoice Amount</th>
                                                     {/* <th>Amount Received</th> */}
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm"><input id="inv_amount" readOnly={"true"} rfnode="value" name="inv_amount" type="text" class="form-control required" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="inv_discount_per" rfnode="value" name="inv_discount_per" defaultValue={"0"} tag_jobject={"{\"t_type\":\"discount\",\"t_source\":\"inv_amount\",\"t_per_val\":\"inv_discount\",\"t_result\":\"inv_net_amount\"}"} type="text" class="form-control required _keyupcustom" /></div></td> 

                                                    <td><div class="input-group input-group-sm"><input id="inv_discount" readOnly={"true"} rfnode="value" name="inv_discount" type="text" class="form-control required" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="inv_net_amount" readOnly={"true"} rfnode="value" name="inv_net_amount" type="text" class="form-control required" /></div></td>
                                                    {/* <td><div class="input-group input-group-sm"><input id="inv_amount_received" rfnode="value" name="inv_amount_received" type="text" class="form-control" /></div></td> */}
                                                </tr>
                                            </thead>
                                        
                                        </table>
                                        
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-5">
                                    </div>
                                    <div class="col-md-7">
                                        <table class="table table-bordered table-sm">
                                            <thead>
                                                <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                    <th>Payment Mode</th>
                                                    <th>Transaction Amount</th>
                                                    <th>Narration</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td><div class="input-group input-group-sm">
                                                      <select id="payment_mode" name="payment_mode" className="form-control piPaymentMember miPaymentMember">
                                                        <option value="0">-Payment Mode-</option>
                                                        <option value={"Cash"}>Cash</option>
                                                        <option value={"Card"}>Card</option>
                                                        <option value={"Cheque"}>Cheque</option>
                                                        <option value={"Netbanking"}>Netbanking</option>
                                                        <option value={"upi"}>UPI</option>
                                                      </select>
                                                      </div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_value" name="pay_value" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><div class="input-group input-group-sm"><input id="pay_narration" name="pay_narration" type="text" class="form-control piPaymentMember miPaymentMember" /></div></td>
                                                    <td><input type="button" class="btn btn-block btn-info btn-sm" clmname="miPaymentMember" cldname="piPaymentMember" cldhidname="piHidPaymentMember" resValue="p_paymentBreakdown" resname="tbPaymentBreakdown" id="_btnPaymentBreakdown" value="add" /></td>
                                                </tr>
                                            </thead>
                                            <tbody id="tbPaymentBreakdown">
                                            </tbody>
                                        </table>
                                        <input type="hidden" id="p_paymentBreakdown" name="p_paymentBreakdown" value="" />
                                        
                                    </div>
                                </div>

            </div>
            <div class="row">
                <div class="col-9"></div>
                    <div class="col-3 float-right">
                        <div class="form-group">

                            <div class="input-group input-group-sm">
                                <button type="button" class="btn btn-sm btn-danger _btnSetColor">Save Transaction</button>
                            </div>
                        </div>
                    </div>
                    <div class="respSSL"></div>

                </div>
            </form>
            {/* end of form data */}
                
              {/* <!-- /.card-body --> */}
            </div>
      </div>
    


    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Import_Crushing logUser={userObject} importItemToBill={this.setImportItemToBill}/>
{/* <Comp_Add_Trans_Item logUser={userObject}/> */}
<Comp_View_Payment_Breakup paymentBreakdown={this.state.stStuPaymentBreakdown}/>
<Comp_View_Invoice_Breakup invoiceBreakdown={this.state.stStuInvoiceBreakdown} />
<Pay_Old_Dues studentId={this.state.stStudentId} stuOldDues={this.state.stStuOldDues}/>
    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Create_Crushing_Bill;