import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';

import Comp_Edit_Fuel_Out from '../ledger_component/comp_edit_fuel_out';
import Comp_Add_Fuel_Out from '../components/comp_add_fuel_out';
import listData from '../function_lib/customeData';

import Comp_Search_Pan from '../components/comp_search_pan';
import { Link } from 'react-router-dom';
import Comp_Print_Report from '../components/comp_print_report';
import Comp_View_Ent_Logs from '../components/comp_view_ent_logs';

let loginUser="";
let userObject="";
let companyName="";
let clsViewFuelOut;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];

let _sumAmount=0;
let _sumNetAmount=0;
let _objAccess={};
class View_Fuel_Out extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
          _objAccess=getAccessFunctions();
      }
      this.state={ stListTransactions:[],stInvoice:{},stPtrInvoice:[],stPtrProduct:[],stPtrPayment:[],
      stEditInvoice:[],stEditProduct:[],stEditPayment:[],stOutStanding:[],stLogState:{},
      stEditPopupItem:[]};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsViewFuelOut=this;
      }

      setLogState(item){
        let map={"table_name":"invoice","table_id":item.invoice_id}
        clsViewFuelOut.setState({stLogState:map});
      }
      
      
      getEditInvoice(item){
        
        let map=[];
        map.push({name:"curl_type",value:"getInvoice"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("2")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"invoice_id",value:btoa(item.invoice_id)})
        map["res_func"]="callBackrrr";
        map["curl_type"]="getInvoice";
        
        CallMapMethod_CBack(clsViewFuelOut,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            let lsData=JSON.parse(data);
            clsViewFuelOut.setState({stEditInvoice:lsData["invoice"],stEditProduct:lsData["purchase_item"],
              stEditPayment:lsData["account_inventory"]
            })
          }
        })
      }



      getTruckProWise(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"view_itemwisereport_new"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"inventory_ledger_id",value:btoa("6")});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});

        if(document.getElementById("ser_party_info_id")!==null){
          map.push({name:"ser_party_info_id",value:$("#ser_party_info_id").val()});
          if($("#ser_party_info_id").val()!==""){
            //alert($("#ser_party_info_id").val());
            
          }
          
        }

        if(document.getElementById("ser_item_id")!==null){
          map.push({name:"ser_item_id",value:$("#ser_item_id").val()});
        }
        
        if(document.getElementById("ser_payment_mode")!==null){
          map.push({name:"ser_payment_mode",value:$("#ser_payment_mode").val()});
        }
        if(document.getElementById("ser_from_date")!==null && document.getElementById("ser_to_date")){
          map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        map.push({name:"ser_to_date",value:$("#ser_to_date").val()});
        }
        if(document.getElementById("ser_vehicle")!==null){
          //alert("i m in vehicle "+$("#ser_vehicle").val());
          map.push({name:"ser_vehicle",value:$("#ser_vehicle").val()});
        }
        
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="view_itemwisereport_new";
        CallMapMethod_CBack(clsViewFuelOut,map,function(data,msg){
          //alert(data);
          _sumAmount=0;
          _sumNetAmount=0;
            if(data!=="0" && data!==""){
              let jsData=JSON.parse(data);
              let listData=jsData["DataList"]
                var strData="";
               
                let vNetAmount=0;
                //alert(JSON.stringify(jsData["OutStanding"]));
                for(let i=0;i<listData.length;i++){
                    // alert(listData[i]["paid_fee"]);
                    // break;
                 
                  vNetAmount+=parseFloat(listData[i]["Amount"]);
                }
                
                $(".outstanding_rpt").html(listData.length+" records found | Net Total Amt:<b>"+vNetAmount+"</b> |");
                clsViewFuelOut.setState({stListTransactions:listData,stOutStanding:jsData["OutStanding"]},()=>{
                  //window.getPagination("#tbData");
                  clsViewFuelOut.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


      okSuccEditInv(data,smg)
      {
        if(data==="001"){
            alert("Record Saved Successfully");
            window.location.reload();
            }else{
              alert(smg);
            }
      } 

      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);

this.clsBtnBreakUpsClick =new listData("_btnFeeBreakdown", "hellow");
this.clsBtnBreakUpsClick.setOnChangeEvent("fee_breakdown",this.clsBtnBreakUpsClick);
this.clsBtnBreakUpsClick.editLoadDocPopup=function setEditLoadDocPopup(ee,index){
  //alert("i am in native click == "+index);
  clsViewFuelOut.clsBtnBreakUpsClick.editCompleteCBack="divAddFuelOut";
  clsViewFuelOut.setState({stEditPopupItem:clsViewFuelOut.clsBtnBreakUpsClick.purchaseItem.tt[index]});
  clsViewFuelOut.clsBtnBreakUpsClick.editIndex=index;
  $("#divEditFuelOut").modal('hide');
  $("#divAddFuelOut").modal('show');
}
//payment breakdown
this.clsBtnPaymentBreadownClick=new listData("_btnPaymentBreakdown","hellow");

this.getTruckProWise();
//this.getMasters();


$('#divAddFuelOut').on('hidden.bs.modal', function () {
  clsViewFuelOut.setState({stEditPopupItem:{"nn":""}},()=>{
    clsViewFuelOut.clsBtnBreakUpsClick.editIndex=-1;
    $("#divEditFuelOut").modal('show');
  });
  
});

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
  <div className="row">
    <div className="col-lg-12 col-md-12 col-sm-12 pb-2">
    <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        Search
      </button>
      <button class="btn btn-sm btn-danger ml-2"  type="button" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>
      <Link className="btn btn-sm btn-danger float-right" to={"../ledger/fuel_out"}>{"Add New Fuel-Out"}</Link>
      {/* <a className="btn btn-sm btn-danger float-right" href="../create_blasting">Add New Blasting</a> */}
    </div>
    
  </div>

<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>View Fuel-Out(Product Wise)</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Date</th>
                      <th>Receipt No.</th>
                      <th>Customer Name</th>
                      <th>Product</th>
                      <th>Unit</th>
                      <th>Quantity</th>
                      <th>Rate</th>
                      <th>Amount</th>
                      <th>Net Amount</th>
                      <th>Vehicle No</th>
                      <th>Vehicle Type</th>
                      <th>Person Name</th>
                      <th>Person Contact</th>
                      <th>Trans.Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                      if(index==0){
                        _sumAmount=0;
                        _sumNetAmount=0;
                      }
                      _sumAmount+=parseFloat(item["Sub Total"]);
                        _sumNetAmount+=parseFloat(item["Amount"]);
                   
                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item["Date"]}</td>
                        <td>{item["Invoice No"]}</td>
                        <td>{item["Company Name"]}</td>
                        <td>{item["Item"]}</td>  
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Quantity"]}</td>
                        <td>{item["Rate"]}</td>
                        <td>{item["Sub Total"]}</td>
                        <td>{item["Amount"]}</td>
                        <td>{item["exp"]}</td>
                        <td>{item["mfg"]}</td>
                        <td>{item["make"]}</td>
                        <td>{item["batch"]}</td>
                        <td>{item["payment_mode"]}</td> 
                    
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    {typeof _objAccess.can_edit?<a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditFuelOut" data-toggle="modal" data-target="#divEditFuelOut" href="javascript:void(0)">View/Edit</a>:''}
                     {/* <a class="dropdown-item" onClick={()=>this.getEditInvoice(item)} data-bs-toggle="modal" data-bs-target="#divEditFuelOut" data-toggle="modal"  data-target="#divEditFuelOut" href="javascript:void(0)">Print</a> */}
                     {typeof _objAccess.access_log!=="undefined"?<a class="dropdown-item" onClick={()=>this.setLogState(item)} data-bs-toggle="modal" data-bs-target="#divViewEntryLogs" data-toggle="modal" data-target="#divViewEntryLogs" href="javascript:void(0)">View Logs</a>:''}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                    <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Rs.<b>{_sumAmount}</b></td>
                    <td>Rs.<b>{_sumNetAmount}</b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
                <table class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr>
                      <td><b>Product</b></td>
                      <td><b>Unit</b></td>
                      <td><b>Net Quantity</b></td>
                      <td><b>Net Amount</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.stOutStanding.map((item,index)=>{
                      return <tr>
                        <td>{item["Item"]}</td>
                        <td>{item["Item Unit"]}</td>
                        <td>{item["Quantity"]}</td>
                        <td>{item["Amount"]}</td>
                      </tr>
                    })}
                  </tbody>


                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

<Comp_View_Ent_Logs logUser={userObject} logState={this.state.stLogState} />

<Comp_Print_Report ptrColumnHeader={["Date","Receipt No.","Customer Name","Product","Unit","Quantity","Rate","Amount","Net Amount",
"Vehicle No","Vehicle Type","Person Name","Person Contact","Tran.Type"
]} 
  ptrColumnName={["Date","Invoice No","Company Name","Item","Item Unit","Quantity","Rate","Sub Total","Amount",
    "exp","mfg","make","batch","payment_mode"
  ]}
  totals={[{"col":"Sub Total","col_index":8,"total_value":0},
    {"col":"Amount","col_index":9,"total_value":0}
  ]}

  
  ptrOutStandingCols={{"Item":"Product","Item Unit":"Unit","Quantity":"Quantity","Amount":"Net Amount"}}
  lsOutStandingData={this.state.stOutStanding}
  ptrOutStandingTitle={"Product/Unit Wise Outstanding"}
  
  lsTabelData={this.state.stListTransactions}
  ptrTitle={"Fuel Out Product Wise"}
  pageType="0"
  logUser={userObject}/>

<Comp_Add_Fuel_Out logUser={userObject} editPopup={this.state.stEditPopupItem}/>
<Comp_Edit_Fuel_Out lsInvoice={this.state.stEditInvoice} lsPurchaseProduct={this.state.stEditProduct}
 lsPayment={this.state.stEditPayment} editUser={""}
 logUser={userObject} 
 clsParentListData={this.clsBtnBreakUpsClick}
 clsParentPaymentButton={this.clsBtnPaymentBreadownClick}
 compTitle={"Edit Fuel Out"}
 />
<Comp_Search_Pan onSearchFunc={this.getTruckProWise} 
searchTitle={"Search Fuel Out"}
searchOptions={{"company_name":"true",
// "inventory_ledger":"true",
"item":"true",
//"site_name":"true",
"vehicle_no":"true",
"payment_mode":"true",
"from_to_date":"true"}}
/>
   
    
          </div>)
      }
}
export default View_Fuel_Out;