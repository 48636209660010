import React,{useEffect} from 'react'
import {CompItem_Auto_Complete,CompItem_Auto_Complete_New} from '../components/comp_auto_complete';
export function Comp_Add_Fuel_Out(props){
    useEffect(()=>{

    },[props.editPopup]);
    return(<div className="modal fade bd-example-modal-xl"  id="divAddFuelOut" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xlll" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Transaction Entry</h5>
          <button type="button" className="close text-white" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAddFuelOut" name="frmAddFuelOut">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value={btoa("okRespCreateParty")} />
                              <input type="hidden" name="curl_type" id="curl_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="m_type" id="m_type" value={btoa("addPartyInfo")} />
                              <input type="hidden" name="log_id" id="log_id" value={props.logUser[0]["id"]}/>
                              <input type="hidden" name="log_app_id" id="log_app_id" value={props.logUser[0]["app_user_id"]}/>
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="category_id" id="category_id" value={btoa("1")}/>
                              
                              <div className="card-body">
                              <table class="table table-bordered table-sm">
                                        <thead>
                                            <tr className="white" style={{"background-color":"#5B84B1FF"}}>
                                                
                                                
                                            </tr>
                                            <tr>
                                            <td>Item</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                    <CompItem_Auto_Complete_New required_mg="Kindly select Item" display_stock={"fuel_avail"} display_stock_name={"lbl_fuel_avail"} comp_sel_text={"auto_item_name"}  comp_id={"sel_auto_item_name"} comp_name={"sel_auto_item_name"} defaultLabel={props.editPopup["auto_item_name"]} defaultValue={props.editPopup["item_id"]} target_obj={"item_id"} autoList={[]} placeHolder={"Product/Item"} propClassHid={"piHidMember miMember"} propClass={"form-control form-control-sm piMember miMember required"} />

                                                    <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    {/* <label class="btn btn-danger">Product Cost</label> */}
                    <button class="btn btn-danger" id="lbl_fuel_avail" tabindex="-1">Stock</button>
                  </div>
                  <input tabindex="-1" id="fuel_avail" name="fuel_avail" readOnly={"true"} class="form-control form-control-sm1" />
                </div>
                                                   
                                                    </div>
                                                </td>
                                                </tr>
                                                <tr>
                                                    <td>Unit</td>
                                                <td><div class="input-group input-group-sm">
                                                  <select id="ser_unit_id" required_mg="Unit Required" name="ser_unit_id" val_memeber="unit_id" class="form-control-sm piMember miMember required">
                                                    <option value={btoa("1")}>LETER(Ltr)</option>
                                                  </select>
                                                  
                                                  </div>
                                                  
                                                  </td>
                                                  </tr>
                                                 
                                                  <tr>
                                                    <td>Quantity</td>
                                                <td><div class="input-group input-group-sm"><input type="text" defaultValue={props.editPopup["quantity"]} id="quantity" name="quantity" required_mg="Quantity Required" relate_control="rate" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Rate</td>
                                                <td><div class="input-group input-group-sm"><input type="text" defaultValue={props.editPopup["rate"]} id="rate" name="rate" required_mg="Rate Required" relate_control="quantity" output_to="amount" class="form-control cd_input_change piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Amount</td>
                                                <td>
                                                    <div class="input-group input-group-sm">
                                                        <input type="text" readOnly={"true"} id="amount" name="amount" defaultValue={props.editPopup["amount"]} class="form-control piMember miMember" />
                                                        {/* <input type="hidden" id="item_id" name="item_id" required_mg={"Select Item Name from List properly"} set_required_focus={"auto_item_name"} class="piHidMember miMember" value={""} /> */}
                                                        <input type="hidden" id="unit_id" name="unit_id" class="piHidMember miMember" defaultValue={props.editPopup["unit_id"]} />
                                                    </div>
                                                </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>Vehicle No.</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="exp" name="exp" defaultValue={props.editPopup["exp"]} required_mg1="Person Name Required"  class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Vehicle Details</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="mfg" name="mfg" defaultValue={props.editPopup["mfg"]} required_mg1="Person Mobile Required"  class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>Person Name</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="make" name="make" required_mg1="Person Name Required" defaultValue={props.editPopup["make"]}  class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td>Person Mobile</td>
                                                    <td><div class="input-group input-group-sm"><input type="text" id="batch" name="batch" defaultValue={props.editPopup["batch"]} required_mg1="Person Mobile Required"   class="form-control piMember miMember" /></div></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                <td><input type="button" validateStock={["quantity","fuel_avail"]} class="btn btn-block btn-info btn-sm" clmname="miMember" cldname="piMember" cldhidname="piHidMember" resValue="p_ItemList" resname="tbItemList" id="_btnFeeBreakdown" value="add" /></td>
                                            </tr>
                                        </thead>
                                       
                                    </table>
                                 
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          {/* <button type="button" className="btn _btnSetColor btn-primary">Save</button> */}
        </div>
        </form>
      </div>
      </div>
      </div>)
}

export default Comp_Add_Fuel_Out;